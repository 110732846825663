
import {Component, Vue} from 'vue-property-decorator';
import CustomPageTitle from "../components/CustomPageTitle.vue";
import AnnouncementTableComponent from "../components/AnnouncementTableComponent.vue";
import {AxiosPromise} from "axios";
import DocumentDetails from "../components/DocumentDetails.vue";
import i18n from "../plugins/i18n";
import TableComponent from "../components/TableComponent.vue";
import MicroSignerComponent from "../components/MicroSignerComponent.vue";
import {InjunctionTakeoverStatus} from "../common/enum/injunction-takeover-status";
import errorHandler from '../error/error-handler';
import {AnnouncementControllerApi, AnnouncementDto, MicroSignerControllerApi, PagedAnnouncementRequest, PagedRequest, PagedResponseAnnouncementDto} from "../client";
import {FileDownloadService} from "../service/file-download-service";
import http from "../plugins/http";

export function displayAnnouncementStatus(value: string) {
    if (value === InjunctionTakeoverStatus.ATVEHETO) {
        return i18n.t(`announcement.status-type.ATVEHETO`);
    } else if (value === InjunctionTakeoverStatus.ATVETT_LETOLTHETO) {
        return i18n.t(`announcement.status-type.ATVETT_LETOLTHETO`);
    } else if (value === InjunctionTakeoverStatus.LETOLTOTT) {
        return i18n.t(`announcement.status-type.LETOLTOTT`);
    } else if (value === InjunctionTakeoverStatus.LEJART) {
        return i18n.t(`announcement.status-type.LEJART`);
    } else if (value === InjunctionTakeoverStatus.ATVETT_NEM_ELERHETO) {
        return i18n.t(`announcement.status-type.ATVETT_NEM_ELERHETO`);
    } else if (value === 'kézbesített') {
        return i18n.t(`announcement.status-type.sent`);
    } else if (value === 'ismeretlen') {
        return i18n.t(`announcement.status-type.unknown`);
    } else {
        return i18n.t(`announcement.status-type.other`);
    }
}

@Component({
    components: {
        CustomPageTitle,
        DocumentDetails,
        AnnouncementTableComponent,
        MicroSignerComponent,
        DocumentDetailsModal: () => import('../components/DocumentDetailsModal.vue')
    },
})
export default class Announcements extends Vue {
    public documentData: AnnouncementDto | null = null;
    private microSignerControllerApi = new MicroSignerControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);
    private announcementControllerApi = new AnnouncementControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);
    private fileDownloadService = new FileDownloadService();
    private filterInjunctionTakeover = false;

    public dialog = false;
    private filterInjunction = false;

    private tableDelegate: TableComponent | null = null;

    private listAnnouncements(request: PagedRequest): AxiosPromise<PagedResponseAnnouncementDto> {
        const requestBody: PagedAnnouncementRequest = {
            paging: request.paging,
            filterInjunction: this.filterInjunction,
            filterInjunctionTakeover: this.filterInjunctionTakeover
        }

        return this.announcementControllerApi.listAnnouncements(requestBody);
    }

    private setTable(table: TableComponent) {
        this.tableDelegate = table;
    }

    private retrieveDetailedInformation(documentData: AnnouncementDto) {
        this.announcementControllerApi.getAnnouncement(documentData.id!!).then((response) => {
                this.documentData = response.data;
            }
        )
    }

    private isTakeoverButtonDisabled() {
        return this.tableDelegate?.selected?.length === 0;
    }

    private signSelectedAcknowledgements() {
        const selectedAnnouncements: AnnouncementDto[] = this.tableDelegate?.selected!!;
        this.announcementControllerApi.startSigningSessionForAnnouncements(selectedAnnouncements.map(announcement => announcement.id!!)).then(
            esignSessionRequest => MicroSignerComponent.methods.sign(esignSessionRequest)
        ).catch(
            error => errorHandler.showError(error.response.data.errors[0].messageKey)
        );
        this.reloadData();
    }

    private onFilterChange(value: boolean) {
        if (value) {
            // set values
            this.filterInjunction = this.filterInjunction || this.filterInjunctionTakeover;
        } else {
            // clear values
            this.filterInjunctionTakeover = this.filterInjunction && this.filterInjunctionTakeover;
        }

        this.reloadData();
    }

    private openDialog(value: AnnouncementDto) {
        this.documentData = null;
        this.retrieveDetailedInformation(value);
        this.dialog = true;
    }

    private closeDialog(value: boolean) {
        this.dialog = value;
    }

    private isNotDownloadable() {
        return !this.documentData
            || !this.documentData.id
            || InjunctionTakeoverStatus.LEJART === this.documentData.injunctionTakeoverStatus
            || InjunctionTakeoverStatus.ATVETT_NEM_ELERHETO === this.documentData.injunctionTakeoverStatus;
    }

    private signIfNeededAndDownloadFile() {
        const injunctionStatus = this.documentData!!.injunctionTakeoverStatus!!;
        const announcementId = this.documentData!!.id!!;
        if (InjunctionTakeoverStatus.ATVEHETO === injunctionStatus) {
            this.announcementControllerApi.startSigningSessionForAnnouncements([announcementId]).then(
                esignSessionRequest => MicroSignerComponent.methods.sign(esignSessionRequest)
            ).catch(
                error => errorHandler.showError(error.response.data.errors[0].messageKey)
            );
        } else {
            this.downloadFile(this.documentData!!);
        }
    }

    private downloadFile(announcement: AnnouncementDto) {
        if (InjunctionTakeoverStatus.ATVETT_LETOLTHETO === announcement.injunctionTakeoverStatus || InjunctionTakeoverStatus.LETOLTOTT === announcement.injunctionTakeoverStatus) {
            this.announcementControllerApi.downloadInjunction(announcement.id!!).then(response => this.fileDownloadService.downloadFile(response.data));
        } else {
            this.announcementControllerApi.downloadAnnouncement(announcement.id!!).then(response => this.fileDownloadService.downloadFile(response.data));
        }
    }

    private downloadInjunction(sessionId: string) {
        this.microSignerControllerApi.download(sessionId)
            .then(value => this.fileDownloadService.downloadFilesZipped(value.data, `cegeljarasportal-vegzesatvetel`))
            .finally(this.reloadData);
    }

    private reloadData() {
        this.closeDialog(false);
        this.tableDelegate?.retrieveData();
    }

    private displayValues(key: string, value: any): any {
        if (key === 'status') {
            return displayAnnouncementStatus(value);
        }

        return value;
    }

    private announcementKeysToIgnore(): string[] {
        return ['injunctionTakeoverType', 'injunctionTakeoverStatus'];
    }
}
