
import {Component, Prop, Vue} from 'vue-property-decorator';
import ColumnDef from '../common/column-def';
import {AxiosPromise} from 'axios';
import TableComponent from './TableComponent.vue';
import {displayAnnouncementStatus} from '../views/Announcements.vue';
import {InjunctionTakeoverStatus} from '../common/enum/injunction-takeover-status';
import {AnnouncementDto, PagedRequest} from '../client';

@Component({
    components: {
        TableComponent
    },
})
export default class AnnouncementTableComponent extends Vue {
    @Prop() retrieveAnnouncements?: ((pagination: PagedRequest) => AxiosPromise) | null;
    @Prop() isRowClickable!: boolean;
    @Prop() downloadFile!: ((announcement: AnnouncementDto) => void) | null;
    @Prop() isMultiSelectable!: boolean;

    private tableDelegate: TableComponent | null = null;

    public announcementHeaders = [
        new ColumnDef({text: this.$t('announcement.subject-title'), value: 'subject'}),
        new ColumnDef({text: this.$t('announcement.recipient'), value: 'recipient'}),
        new ColumnDef({text: this.$t('announcement.status'), value: 'status'}),
        new ColumnDef({text: this.$t('announcement.created'), value: 'created', isDate: true}),
        new ColumnDef({text: this.$t('announcement.download'), value: 'download'}),
    ];

    public displayStatus = displayAnnouncementStatus

    public mounted() {
        this.$emit('delegate', this.tableDelegate);
    }

    private setTable(table: TableComponent) {
        this.tableDelegate = table;
    }

    private openDialog(value: AnnouncementDto) {
        this.$emit('openDialog', value);
    }

    private isAcknowledgementNeedToBeSigned(item: AnnouncementDto) {
        return item.injunctionTakeoverStatus === InjunctionTakeoverStatus.ATVEHETO
    }

    private isDownloadDisabled(item: AnnouncementDto) {
        return item.injunctionTakeoverStatus != null && item.injunctionTakeoverStatus !== InjunctionTakeoverStatus.ATVETT_LETOLTHETO && item.injunctionTakeoverStatus !== InjunctionTakeoverStatus.LETOLTOTT
    }
}
